import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { locale, loadMessages } from "devextreme/localization";
import './index.css';
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './dx-styles.scss';
import esAppMessages from "devextreme/localization/messages/es.json";

import reportWebVitals from './reportWebVitals';
import Root from './Root';

loadMessages(esAppMessages);
locale('es');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
