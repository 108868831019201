import './page-loader.scss'

export default function PageLoader() {
  return (<div className="lds-container">
  <div className="lds-all"> 
    <div className="lds-ripple">
      <div>
        
      </div>
      <div>
  
      </div>
  
    </div>
  </div>
  </div>)

}