import {  Suspense, lazy } from "react";
import { useScreenSizeClass } from './utils/media-query';
import { NavigationProvider } from './contexts/navigation';
import { HashRouter as Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient,  } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StateContextProvider } from "./contexts";
import PageLoader from "./components/page-loader/page-loader";

const App = lazy(()=> import( './App'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});


export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <QueryClientProvider client={queryClient} >
        <StateContextProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <Suspense fallback={<PageLoader />}>
                <App />
              </Suspense>
            </div>
          </NavigationProvider>
        </StateContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  );
}
